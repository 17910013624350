import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllProperties extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`CategoryProperty/GetAll?pageNumber=${data.pageNumber}&count=${data.count}&searchCommand=${data.search}`);
    }
}

class DeleteProduct extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`Product/Delete?id=${data.id}`);
    }
}


class UpdateProduct extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Product/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateProduct extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Product/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}
class GetProductsForVendor extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Product/GetProductsForVendor');
    }
    setParams(data) {
        super.setRequestParam(data)
    }
}
class GetProductById extends WebServiceRequest {
    constructor(context) {
        super(context)
    }
    setParams(data) {
        super.setTag(`Product/Get?productId=${data.productId}`);
    }

}
class GetProductByIdForVendor extends WebServiceRequest {
    constructor(context) {
        super(context)
    }
    setParams(data) {
        super.setTag(`Product/GetForVendor?productId=${data.productId}`);
    }

}
class GetProductCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`ProductCategory/Get`);
    }
    setParams(data) {
        super.setRequestParam(data)
    }

}
class UpdateProductCategorySEO extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`ProductCategory/UpdateSEO`);
    }
    setData(data) {
        super.setRequestParamDataObj(data)
    }

}
class UpdateProductSEO extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Product/UpdateSEO`)
    }
    setData(data) {
        super.setRequestParamDataObj(data);
    }

}
class UpdatePrice extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Price/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}
class ProductCategoryGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ProductCategory/GetAll')
    }
}
class CopyProductObjects extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Product/CopyObject')
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class ChangeIsInStock extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Product/ChangeStock')
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class ChangeProductStatus extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Product/ChangeStatus')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    CopyProductObjects,
    ChangeIsInStock,
    ChangeProductStatus,
    UpdateProductSEO,
    GetAllProperties,
    GetProductCategory,
    GetProductByIdForVendor,
    UpdateProductCategorySEO,
    GetProductsForVendor,
    GetProductById,
    DeleteProduct,
    UpdateProduct,
    UpdatePrice,
    CreateProduct,
    ProductCategoryGetAllRequest
};
